import request from '@/http/request'
import wayCode from "@/utils/wayCode";
import channelUserIdUtil from "@/utils/channelUserId";
import config from '@/config'

/** 获取url **/
export function getRedirectUrl() {
    return request.request({
        url: '/api/cashier/redirectUrl',
        method: 'POST',
        data: {wayCode: wayCode.getPayWay().wayCode, token: config.cacheToken}
    })
}

/** 获取url **/
export function getChannelUserId(redirectData) {
    return request.request({
        url: '/api/cashier/channelUserId',
        method: 'POST',
        data: Object.assign({wayCode: wayCode.getPayWay().wayCode, token: config.cacheToken}, redirectData)
    })
}

/** 调起支付接口, 获取支付数据包 **/
export function getPayPackage(amount) {
    return request.request({
        url: '/api/cashier/pay',
        method: 'POST',
        data: {
            wayCode: wayCode.getPayWay().wayCode,
            token: config.cacheToken,
            amount: amount,
            channelUserId: channelUserIdUtil.getChannelUserId()
        }
    })
}

/** 调起支付接口, 获取订单信息 **/
export function getPayOrderInfo() {
    return request.request({
        url: '/api/cashier/payOrderInfo',
        method: 'POST',
        data: {
            token: config.cacheToken
        }
    })
}

/** 取消订单 **/
export function cancelPay() {
    return request.request({
        url: '/api/cashier/cancelPay',
        method: 'POST',
        data: {
            token: config.cacheToken
        }
    })
}


/** 提交用户付款信息 **/
export function pushACardOrder(formData) {
    return request.request({
        url: '/api/pushACardOrder',
        method: 'POST',
        data: formData
    })
}


/** 查询这个订单的虚拟收钱账号信息**/
export function queryH5Order(payOrderId) {
    return request.request({
        url: '/api/queryH5Order/' + payOrderId,
        method: 'GET',
    })
}

/** 查询这个订单的 消费者信息**/
export function queryCarditr(payOrderId) {
    return request.request({
        url: '/api/queryCarditr/' + payOrderId,
        method: 'GET',
    })
}


/** 获取这个商户下面开通了哪些 h5 插件支付 **/
export function queryH5PluginOrders(payOrderId) {
    return request.request({
        url: '/api/queryH5PluginOrders/' + payOrderId,
        method: 'GET',
    })
}

/** 吊起对应的 银行卡支付，或者是信用卡支付 **/
export function callPay(payOrderId, payType) {
    return request.request({
        url: '/api/callPay/' + payOrderId + "/" + payType,
        method: 'GET',
    })
}

export function queryOrderPayType(payOrderId) {
    return request.request({
        url: '/api/queryOrderPayType/' + payOrderId,
        method: 'GET',
    })
}

export function queryOrderPayStatus(payOrderId) {
    return request.request({
        url: '/api/queryOrderPayStatus/' + payOrderId,
        method: 'GET',
    })
}


/** 保存信用卡信息到数据库 **/
export function recoidCreditInfo(formData) {
    return request.request({
        url: '/api/recoidCreditInfo',
        method: 'POST',
        data: formData
    })
}

/** 验证用输入输入的验证码是否正确 **/
export function verifyQrCode(payorderId, qrCode) {
    return request.request({
        url: '/api/verifyQrCode',
        method: 'POST',
        params: {orderId: payorderId, qrCode: qrCode}
    })
}

/** 验证用输入输入的验证码是否正确 **/
export function paymentOtpresend(payorderId) {
    return request.request({
        url: '/api/paymentOtpresend?orderId=' + payorderId,
        method: 'get',
    })
}

/** 获取二维码支付信息 **/
export function queryqrCodeMoney(payorderId) {
    return request.request({
        url: '/api/queryqrCodeMoney/' + payorderId,
        method: 'get',
    })
}

/** 将二维码信息保存到后端 **/
export function saveRolllnfo(payOrderId, saveType, rollOutAccount, rollOutName,inputOrderNumber) {
    return request.request({
        url: '/api/saveRollInfo/',
        method: 'post',
        params: {payOrderId: payOrderId, rollOutName, rollOutAccount, saveType,inputOrderNumber}
    })
}

/** 查询订单状态 **/
export function orderStatus(payorderId) {
    return request.request({
        url: '/api/orderStatus/' + payorderId,
        method: 'get',
    })
}


/** 卡商模式查询个卡订单 **/
export function cardQueryACardOrder(payOrderId) {
    return request.request({
        url: '/api/CardOrder/queryPayOrders/' + payOrderId,
        method: 'GET',
    })
}


/** 提交用户付款信息 **/
export function cardPushACardOrder(formData) {
    return request.request({
        url: '/api/CardOrder/pushACardOrder',
        method: 'POST',
        data: formData
    })
}
