<template>
  <div class="content_tt">
    <div class="header_uh">{{ PageInfo.orderId }}</div>
    <div class="content_tc">
      <div class="tcheaderCT">
        <div>
          <img src="../../assets/images/qrcode/guoqi.png" style="width:40px;" mode="widthFix"/>
          <div>राष्ट्रीय फ्लैग</div>
        </div>
        <div>
          <div class="ty1">Valor do deposlto</div>
          <div class="ty"><span>{{ PageInfo.currency }}</span>{{ PageInfo.money }}</div>
        </div>
      </div>

      <div class="tcheader">
        <div class="info-hint">Por favor o rome CPF do pagedof</div>
        <div class="sxc">
          <img src="../../assets/images/qrcode/siyc.png" style="width:50px;" mode="widthFix"/>
          <div>INR</div>
        </div>
        <div class='inputText'>
          <input type="text" @click="inputText1" v-model="rollOutName" placeholder="Enter your name"/>
          <div class="placehol" v-if="show1">Please enter your name</div>
        </div>
        <div class='inputText'>
          <input type="text" @click="inputText2" v-model="rollOutAccount" placeholder="Enter the payment amount"/>
          <div class="placehol" v-if="show2">Please enter the payment amount</div>
        </div>
        <div class="bf_foot">
          If you use a friend's account to recharge,
          please fill in your friend's real name and CPF,
          otherwise the name and CPF of the payment account are inconsistent with the real
          content, and the recharge will not be recognized
        </div>
      </div>
      <button class="submit-but" @click="submit()">Ir para pagar</button>
    </div>

  </div>
</template>

<script>
// import {
// 	getpayOrderId,
// 	saveRolllnfo
// } from '@/core/tcpagesT1.js';
import {queryqrCodeMoney, saveRolllnfo} from '@/api/api'

export default {
  data() {
    return {
      payOrderId: null,
      // show1:false,
      // show2:false,
      show1: true,
      show2: true,
      PageInfo: [],
      rollOutName: '',
      rollOutAccount: '',
      saveType: 0
    }
  },
  mounted() {
    this.payOrderId = this.$route.params.id
    this.getpayData();
  },
  methods: {
    inputText1() {
      this.show1 = true;
    },
    inputText2() {
      this.show2 = true;
    },
    submit() {
      if (this.rollOutName == '') {
        this.$message.error('Please enter your real name');
      } else if (this.rollOutAccount == '') {
        this.$message.error('Please re-enter your CPF account');
      } else {
        // eslint-disable-next-line no-unused-vars
        saveRolllnfo(this.payOrderId, this.saveType, this.rollOutAccount, this.rollOutName, null).then(res => {
          this.$message({
            message: res,
            type: 'success',
            duration: '1000',
            onClose: () => {
              this.$router.push({path: '/qrcodeSave/' + this.payOrderId})
            }
          });
          // this.$router.push("../qrcode/TC2022?payOrderId"+this.payOrderId)
        }).catch(err => {
          console.log(err)
        })
      }
    },
    getpayData() {
      console.log(this.payOrderId)
      queryqrCodeMoney(this.payOrderId).then(res => {
        console.log(res)
        this.PageInfo = res
      })
    }
  }
}
</script>

<style scoped>
body {
  font-size: 20px !important;
}

.info-hint {
  text-align: left;
}

.inputText > input {
  background: none;
  outline: none;
  border: none;
}

.header_uh {
  background-color: #724ec6;
  color: #fff;
  text-align: center;
  height: 90px;
  line-height: 90px;
  font-size: 40px;
}

.content_tt {
  background-color: rgba(200, 181, 245, 0.2);
  height: 100vh;
  text-align: left;
  font-size: 30px;
  /* padding:30rpx 20rpx; */
}

.content_tc {
  padding: 30px 20px;
}

.tcheaderCT, .tcheader {
  /* align-items:center; */
  box-shadow: 2px 2px 10px rgba(200, 181, 245, 0.3);
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0px;
  /* justify-content: flex-start; */
}

.tcheaderCT {
  display: flex;
}

.tcheaderCT > div:nth-child(1) {
  padding-right: 100px;
  font-size: 26px;
  color: #724ec6;
  text-align: center;
}

.tcheader > div:nth-child(1) {
  font-size: 35px;
  color: #724ec6;
}

.sxc {
  display: flex;
  align-items: center;
  /* margin:30rpx 0rpx; */
}

.ty1 {
  font-size: 40px;
  font-weight: 300;
}

.ty {
  font-size: 50px;
  font-weight: 700;
  padding: 10px;
  text-align: center;
}

.ty span {
  font-size: 30px;
  margin-right: 10px;
}

.inputText {
  padding: 10px 20px;
  border: 1px solid rgba(123, 24, 169, 0.8);
  border-radius: 5px;
  margin: 10px 0px 30px;
  position: relative;
  display: flex;
  align-items: center;
}

.inputText input {
  width: 330px;
}

.inputText::before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #da0e73;
  position: absolute;
  right: 40%;
  top: 50%;
  transform: translateY(-50%);
}

.placehol {
  width: 220px;
  padding: 5px 20px;
  text-align: left;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 8px;
  margin-left: 70px;
  position: relative;
}

.placehol::after {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  /* background-color:rgba(0,0,0,0.7); */
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid rgba(0, 0, 0, 0.7);
  /* background-color: #724ec6; */
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);

}

.inputText input {
  padding: 10px 20px;
}

.bf_foot {
  font-size: 30px;
  color: #000;
  margin: 10px 0px 20px;
}

.submit-but {
  background-color: #724ec6;
  width: 100%;
  color: #fff;
  padding: 2px 5px;
  margin: 31px 0px;
  box-sizing: border-box;
  font-size: 0.45rem;
  outline: none;
  border: none;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.1rem;
  overflow: hidden;
}
</style>
